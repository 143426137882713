
import Vue from 'vue';

import { GetterTree, ActionTree, MutationTree } from 'vuex'

import {RootState} from "../index"
import BgServer from '~/modules/BgServer';
import ModelCollectionAccount from '~/modules/model/ModelCollectionAccount';
import DataSync from '~/modules/utility/DataSync';
import ArraySortedAccount from '~/modules/ArraySortedAccount';
import ModelListAccount from '~/modules/ModelListAccount';

import {IAccountLog} from "~/modules/model/Types/IAccountLog"
import ModelArray from '~/modules/model/ModelArray';
import Account from '~/modules/model/Types/Account';



/***************************************************************/
/**                        STATE                              **/
/***************************************************************/

/*
 содержит
    accountMain?:    Account        // главный счет
    accountRating?:  Account        // рейтинг
    accountsItem:   Array<Account>  // покупки      
    accountsMoney:  Array<Account>  // все деньги     
    
    как пользоваться: 
        
    выводишь  accountMain  в навбар - там где выводится текущий счет        
    выводишь  accountsItem в окно магазина в раздел "мои покупки"        
*/


export const state = () => { return {     
    //accountsAll:{} as ModelCollectionAccount,
    accountMain:null as  Account | null,
    accountRating: null as   Account | null,
    accountsItem: [] as Account[],
    accountsMoney: [] as Account[],

    accountMoneyHistory: [] as IAccountLog[],
    accountMoneyHistoryCount: null as number | null,

    accountRatingHistory: [] as IAccountLog[],
    accountRatingHistoryCount: null as number | null,
};    
};

export type ModuleState = ReturnType<typeof state>


/***************************************************************/
/**                       GETTERS                             **/
/***************************************************************/

export const getters: GetterTree<ModuleState, RootState> = {     
}


/***************************************************************/
/**                      MUTATIONS                            **/
/***************************************************************/

export const mutations: MutationTree<ModuleState> = {
    SYNC_ARRAYS( state , list:ArraySortedAccount) {
        DataSync( state, list );
    }     
}



/***************************************************************/
/**                      ACTIONS                              **/
/***************************************************************/
let g_server:  BgServer
export const actions: ActionTree<ModuleState, RootState> = {     
    async init({dispatch,commit}, server:BgServer) {  
        g_server = server;                   
        server.modelAccount.on( ModelListAccount.EVENT_DATA_CHANGED , (list:ArraySortedAccount)=>{    
            commit("SYNC_ARRAYS", list);
        })   
        
        
        server.modelAccount.on( ModelListAccount.EVENT_HISTORY_MAIN, (arrayModels:ModelArray<IAccountLog> )=>{    
            commit("SYNC_ARRAYS", { 
                accountMoneyHistory : arrayModels.models,      
                accountMoneyHistoryCount : arrayModels.countTotal,
            });            
        })                

        server.modelAccount.on( ModelListAccount.EVENT_HISTORY_RATING, (arrayModels:ModelArray<IAccountLog> )=>{   
            commit("SYNC_ARRAYS", {                
                accountRatingHistory : arrayModels.models,      
                accountRatingHistoryCount : arrayModels.countTotal,
            });            
        }) 
        
        server.on( 'changeAccountMain', (arrayModels:Account )=>{
            //console.log('model acc change')    
            commit("SYNC_ARRAYS", {                
                accountMain: arrayModels
            });           
        }) 
    },

    async loadMoreHistoryMain({}) {
        return await g_server.modelAccount.doLoadMoreHistoryMain();        
    },
    async loadMoreHistoryRating({}) {
        return await g_server.modelAccount.doLoadMoreHistoryRating();        
    },
    async loadHistoryMain({}) {
        return await g_server.modelAccount.doLoadHistoryMain();        
    },
    async loadHistoryRating({}) {
        return await g_server.modelAccount.doLoadHistoryRating();        
    },


}






