
import Vue from 'vue';
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import {RootState} from "../index"

import screenfull from "screenfull"
import logger from '~/modules/utility/logger';
import { isMobile } from 'mobile-device-detect';
import NoSleep from "nosleep.js"

let noSleep = new NoSleep();

let rootCommit:any = null;
if (screenfull.isEnabled) {
    screenfull.on("change",() => {        
        logger.debug("screen full change");
        if(!rootCommit)
            return;                 
        rootCommit("SET_FULL_SCREEN", (<screenfull.Screenfull>screenfull).isFullscreen);    
    });
}




/***************************************************************/
/**                        STATE                              **/
/***************************************************************/

export const state = () => { return {  
    
    inFullScreen: false as Boolean,
    needFullSceen: true as Boolean,
    isMobile: isMobile as boolean,
};    
};

export type ModuleState = ReturnType<typeof state>


/***************************************************************/
/**                       GETTERS                             **/
/***************************************************************/

export const getters: GetterTree<ModuleState, RootState> = {     
}


/***************************************************************/
/**                      MUTATIONS                            **/
/***************************************************************/

export const mutations: MutationTree<ModuleState> = {    
    SET_FULL_SCREEN(state, value) {
        state.inFullScreen = value;
    },
    SET_NEED_FULL_SCREEN(state, value) {
        state.needFullSceen = value;
    }        
}

/***************************************************************/
/**                      ACTIONS                              **/
/***************************************************************/

export const actions: ActionTree<ModuleState, RootState> = {  

    async init({commit, dispatch}) {                  
        commit("SET_NEED_FULL_SCREEN", isMobile);
        
        if (process.env.FULLSCREEN_DISABLE=="true")
            commit("SET_NEED_FULL_SCREEN", false);
    },            
    async goFullScreenLockNoSleep({dispatch}) {        
        dispatch("goFullScreen");
        //dispatch("goFullScreen");
    },
    async goFullScreen({commit}) {
        try {
            if ( process.env.FACEBOOK_INSTANT_GAME ) {
                (document.getElementById('canvas') as any).requestFullscreen()
                return;
            } 
            
            if (!screenfull.isEnabled)
                return logger.warn("fullscreen not supported");
                
            rootCommit = commit;
            await screenfull.request();            
        }
        catch(err) {                                     
            //logger.error("error fullscreen",err);
        }        
    },    

    async noSleepLock() {
        try {
            noSleep.enable();
        } catch(err) {            
            logger.warn("error nosleep",err);
        }
    }
}






