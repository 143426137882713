import Vue from 'vue';
//import socket from '~/plugins/socket.io'
import logger from '~/modules/utility/logger'

import { GetterTree, ActionTree, MutationTree } from 'vuex'

import type axios from "@nuxtjs/axios"
import Player, { IPlayer } from '~/modules/model/Types/Player';
import Account from '~/modules/model/Types/Account';
import { IUserInfo } from '~/modules/connectors/SocketConnector';
import BgServer from '~/modules/BgServer';
import UserConnector from '~/modules/connectors/UserConnector';
import ModelCollectionPlayer from '~/modules/model/ModelCollectionPlayer';
import ModelListPlayer from '~/modules/ModelListPlayer';
import ArraySortedPlayer from '~/modules/ArraySortedPlayer';
import DataSync from '~/modules/utility/DataSync';
import { IPlayerSettings } from '~/modules/model/Types/IPlayerSettings';

/**
 *  
 * TODO see https://vuex-orm.github.io/vuex-orm
 * https://vuex-orm.github.io/vuex-orm/guide/prologue/what-is-vuex-orm.html#how-vuex-orm-handles-data 
 * 
 */


export const state = () => { return {        
    accountRatingOld: null as   Account | null,
    player: null as Player | null,
    loggedIn: false as boolean,
    isAdmin: false as boolean,
    allowLogout: false as boolean,

    //platform: "MOBILE_WEB" as string,
    platform: "" as string, //platform of facebook
    showAdvicer: false as boolean,
    //Robokassa payments always available by default (replaced by Facebook if under Facebook, etc.):
    purchaseEnable: true as boolean,

    loading: false as boolean,
    online:  false as boolean,
    pending: false as boolean,
    gameLoad: false as boolean,
    authPending: false as boolean,
    sendMessage: false as boolean,

    sessionId: null as string | null,
};    
};

export type RootState = ReturnType<typeof state>

/***************************************************************/
/**                       GETTERS                             **/
/***************************************************************/

//export const getters = {
export const getters: GetterTree<RootState, RootState> = {  
    // currentConversation: (state) => {
    //     return state.currentConversationId ? state.conversations[state.currentConversationId] : null
    // },     
        
}
 
/***************************************************************/
/**                      MUTATIONS                            **/
/***************************************************************/

// TODO migrate to ORM
//export const mutations = {
export const mutations: MutationTree<RootState> = {  
      
    SET_USER_INFO(state, userInfo:IUserInfo) {
        if(!userInfo.player || !userInfo.logined) {
            state.player = null;            
            state.loggedIn = false;    
            return;                    
        }
        
        Vue.set(state, "player" , new Player(userInfo.player) )        
        state.loggedIn = userInfo.logined;
        state.isAdmin = userInfo.isAdmin;        
    },
    SET_PLAYER_SETTINGS(state, settings:IPlayerSettings) {
        let player = state.player;
        if(!player)
            return;

        let settingsNew = {...player.settings, ...settings}
        Vue.set(player, "settings", settingsNew);
        player.avatarCode = player.settings.avatar_type;
    },
    SYNC_ARRAYS( state , list:ArraySortedPlayer) {
        DataSync( state, list );
    },

    SET_PLATFORM(state, value: FBInstant.Platform ) {
        state.platform = value        
    },
    SET_SHOW_ADVICER(state, value:boolean) {
        state.showAdvicer = value;
    },
    SET_PURCHASE_ENABLE(state, value:boolean) {
        state.purchaseEnable = value;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_AUTH_PENDING(state, value) {
        state.authPending = value;
    },
    SET_ONLINE(state, value) {
        if(value != state.online)
            logger.log(value ? "online": "offline");
        state.online = value;
    },
    SET_PENDING(state, value) {
        logger.log( "pending", value?"on":"off");
        state.pending = value;
    },
    SET_GAME_LOAD(state, value) {
        state.gameLoad = value;
    },
    SET_SEND_MESSAGE(state, value) {
        state.gameLoad = value;
    },

    SET_SESSION(state, value) {
        //logger.debug("Session set", value);
        state.sessionId = value;
    },
    RATING_OLD( state , value:Account | null) {
        state.accountRatingOld = value;
    }   
}



/***************************************************************/
/**                      ACTIONS                              **/
/***************************************************************/
let g_server:BgServer
//export const actions = {
export const actions: ActionTree<RootState, RootState> = { 

    async init({dispatch,commit}, server:BgServer) {  
        g_server = server;              
        
        server.modelPlayer.on( ModelListPlayer.EVENT_DATA_CHANGED , (data:ArraySortedPlayer)=>{
            logger.debug("Set player info ", data);
            commit("SYNC_ARRAYS", data);
        });

        await Promise.all([            
            dispatch("screen/init"),
            dispatch("game/init", server),
            dispatch("account/init", server),            
            dispatch("tournament/init", server),
            dispatch("trade/init", server),
        ]);      
        
        server.on(BgServer.EVENT_ONLINE, (value)=>{        
            commit("SET_ONLINE", value)
        })        
        commit("SET_ONLINE", g_server.isOnline());

        server.on(BgServer.EVENT_PENDING_CHANGED, (value)=>{            
            commit("SET_PENDING", value)            
        })
        commit("SET_PENDING", g_server.isPending())


        server.on(BgServer.EVENT_AUTH_PENDING_CHANGED, (value)=>{
            commit("SET_AUTH_PENDING", value)
        })
        commit("SET_AUTH_PENDING", g_server.authPendig)

        server.on(BgServer.EVENT_SESSION_CHANGED, (value)=>{
            commit("SET_SESSION", value);            
        })        
        commit("SET_SESSION", g_server.getSessionId())
    },    

    async getStatistics({}, uuid ) {
        if(!g_server)
            return;        
        return g_server.modelPlayer.getStatistics( uuid )
    },

    async doViewPlayer({}, uuid ) {
        if(!g_server)
            return;        
        let data = await g_server.modelPlayer.connectorPlayer.doView( uuid )
        return new Player(data);
    },

    async doSetSettings({state,commit}, value:IPlayerSettings ) {
        if(!g_server)
            return;        
        let result = await g_server.modelPlayer.connectorPlayer.doSetSettings( value )   
        commit("SET_PLAYER_SETTINGS", value);        
        if(state.player && state.player.name != value.nickname)
            g_server.restartConnection(); // hack way to update player name 
        
        
        g_server.modelGame.doClearHistoryGame(true); // hack way to update player avatar in history 
             
        return result;                
    },

    async doLogin( {}, { username, password } : { username : string, password : string } ) {
        if(!g_server)
            return
        return g_server.connectorUser.doLogin( username, password )
    },
    async doLoginDemo( {} ) {
        if(!g_server)
            return
        return g_server.connectorUser.doLoginDemo()
    },
    async doSignUp( {}, { username, password, email } : { username : string, password : string, email : string } ) {
        if(!g_server)
            return
        return g_server.connectorUser.doSignUp( username, password, email )
    },
    async doRequestPasswordReset( {}, email : string ) {
        if(!g_server)
            return
        return g_server.connectorUser.doRequestPasswordReset( email )
    },
    async doLogout( {} ) {
        if(!g_server)
            return
        return g_server.connectorUser.doLogout()
    },

    async reconnect({}) {
        if(!g_server)
            return;
        g_server.restartConnection();
    },
    
    async loadFinish({}) {
        if(!g_server)
            return;
        g_server.onLoadFinish();
    },

    async doSendFeedback({}, message:string) {
        if(!g_server)
            return; 
        await g_server.connectorSupport.sendMessage( message )
    },
}