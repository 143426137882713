
import Vue from 'vue';

import { GetterTree, ActionTree, MutationTree } from 'vuex'

import {RootState} from "../index"
import BgServer from '~/modules/BgServer';
import DataSync from '~/modules/utility/DataSync';
import ModelCollectionTournament from '~/modules/model/ModelCollectionTournament';
import Tournament, { ETournamentStatus, TournamentUuid } from '~/modules/model/Types/Tournament';
import ArraySortedTournament from '~/modules/ArraySortedTournament';
import ModelListTournament from '~/modules/ModelListTournament';
import ModelArray from '~/modules/model/ModelArray';


/***************************************************************/
/**                        STATE                              **/
/***************************************************************/




export const state = () => { return {    
    //tournamentsAll:{} as ModelCollectionTournament,
    tournamentsOpen: [] as Tournament[],
    tournamentsInPlay: [] as Tournament[],
    
    tournamentsFinished: [] as Tournament[],
    tournamentsFinishedCount: null as number | null,

    tournamentsCanceled: [] as Tournament[],    
    tournamentsCanceledCount: null as number | null,

    notifyCount: 0 as number,    
};    
};

export type ModuleState = ReturnType<typeof state>


/***************************************************************/
/**                       GETTERS                             **/
/***************************************************************/

export const getters: GetterTree<ModuleState, RootState> = {     
}


/***************************************************************/
/**                      MUTATIONS                            **/
/***************************************************************/

export const mutations: MutationTree<ModuleState> = {
    SYNC_ARRAYS( state , data:object ) {
        DataSync( state, data );
    }        
}



/***************************************************************/
/**                      ACTIONS                              **/
/***************************************************************/

let g_server:  BgServer
export const actions: ActionTree<ModuleState, RootState> = {         
    async init({dispatch,commit}, server:BgServer) {             
        g_server = server;
        server.modelTournament.on( ModelListTournament.EVENT_DATA_CHANGED , (list:ArraySortedTournament )=>{    
             commit("SYNC_ARRAYS", list);
        })                

        server.modelTournament.on( ModelListTournament.EVENT_LIST_CANCELED , (arrayModels:ModelArray<Tournament> )=>{    
            commit("SYNC_ARRAYS", {
                "tournamentsCanceled": arrayModels.models ,
                "tournamentsCanceledCount": arrayModels.countTotal,
            });            
        })                
        server.modelTournament.on( ModelListTournament.EVENT_LIST_FINISHED , (arrayModels:ModelArray<Tournament> )=>{    
            commit("SYNC_ARRAYS", {
                "tournamentsFinished":arrayModels.models,
                "tournamentsFinishedCount":arrayModels.countTotal,
            });
        })                
    },
    async loadMoreCanceled({}) {
        return await g_server.modelTournament.doLoadMoreRecordsCanceled();        
    },
    async loadCanceled({}) {
        return await g_server.modelTournament.doLoadRecordsCanceled();        
    },
    async loadMoreFinished({}) {        
        return await g_server.modelTournament.doLoadMoreRecordsFinished();        
    },
    async loadFinished({}) {        
        return await g_server.modelTournament.doLoadRecordsFinished();        
    },
    async doView( {}, uuid : TournamentUuid ) {
        return await g_server.modelTournament.getConnector().doView( uuid )
    },
    async doJoin( {}, uuid : TournamentUuid ) {
        return await g_server.modelTournament.getConnector().doJoin( uuid )
    },
}






